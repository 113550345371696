import { Injectable } from '@angular/core';

import { EXAMPLE_FILTERS } from '@app/services/filter/example-filters.const';
import { Filter, FilterTypes, ModalTitles } from '@app/shared/models/filters/filters';
import { ENV } from 'src/environments/environment';

import { StorageService, STORAGE_CONSTANTS } from '../storage';

// TODO: Refactor this class to remove all the ifs
@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private readonly exampleEnvironmentKey = `${ENV.name}-${FilterTypes.EXAMPLE}`;
  private readonly publicationEnvironmentKey = STORAGE_CONSTANTS.PUBLICATIONS_SEGMENT_TAG_SELECT;
  private exampleFilterSelected!: Filter | undefined;
  private publicationFilterSelected!: Filter | undefined;

  constructor(private storage: StorageService) {}

  async getFilters(filterType: FilterTypes) {
    if (filterType === FilterTypes.EXAMPLE) {
      return EXAMPLE_FILTERS;
    }

    if (filterType === FilterTypes.PUBLICATION) {
      const filterSegments = await this.storage.get(STORAGE_CONSTANTS.PUBLICATIONS_SEGMENTS_TAGS);
      return filterSegments;
    }
  }

  async setFilterSelected(filterSelected: Filter, filterType: FilterTypes): Promise<void> {
    if (filterType === FilterTypes.EXAMPLE) {
      this.exampleFilterSelected = filterSelected;
      return this.storage.set(this.exampleEnvironmentKey, filterSelected);
    }

    if (filterType === FilterTypes.PUBLICATION) {
      this.publicationFilterSelected = filterSelected;
      return this.storage.set(this.publicationEnvironmentKey, filterSelected);
    }
  }

  async removeFilterSelected(filterType: FilterTypes): Promise<void> {
    if (filterType === FilterTypes.EXAMPLE) {
      this.exampleFilterSelected = undefined;
      return this.storage.set(this.exampleEnvironmentKey, undefined);
    }
    if (filterType === FilterTypes.PUBLICATION) {
      this.publicationFilterSelected = undefined;
      return this.storage.remove(this.publicationEnvironmentKey);
    }
  }

  async getFilterSelected(filterType: FilterTypes) {
    let filterSelected;
    if (filterType === FilterTypes.EXAMPLE) {
      filterSelected = this.exampleFilterSelected || (await this.storage.get(this.exampleEnvironmentKey));
      return filterSelected;
    }
    if (filterType === FilterTypes.PUBLICATION) {
      filterSelected = this.publicationFilterSelected || (await this.storage.get(this.publicationEnvironmentKey));
      return filterSelected;
    }
  }

  getModalTitle(filterType: FilterTypes) {
    if (filterType === FilterTypes.EXAMPLE) {
      return ModalTitles.EXAMPLE;
    }
  }
}
