import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Address } from '../../models/personal-data/address.model';

@Pipe({ name: 'address' })
export class AddressPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(address: Address): string {
    if (!address) {
      return this.translate.instant('PROFILE.PERSONAL_DATA.ADDRESS.EMPTY');
    }

    let formattedAddress = '';

    formattedAddress = `${address.streetTypeName} ${address.streetName} ${address.streetNumber}`;

    const stairway = ` ${address.stairway ? address.stairway + ',' : ''}`;
    const floor = ` ${address.floorNumber ? address.floorNumber + ',' : ''}`;
    const door = ` ${address.doorNumber ? address.doorNumber + ',' : ''} `;

    formattedAddress += `,${stairway}${floor}${door}  ${address.postalCode}`;

    const place = `${address.province.name}, ${address.city.name}`;
    formattedAddress += `\n${place}`;

    return formattedAddress;
  }
}
