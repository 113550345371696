import { Location } from '@angular/common';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import {
  Logger,
  ModalManager,
  NavigationEvent,
  NavigationEvents,
  NavigationService,
  NetworkService
} from '@app/services';
import { ErrorCodes, ErrorMessages } from '@app/services/error/error.model';
import { NavigationHelperService } from '@app/services/navigation/navigation.helper.service';
import { UserService } from '@app/services/user/user.service';
import { PAGES, PTRAB_PAGES } from '@app/shared/enums/pages/pages.enum';
import { getPtrabUrlFromMap } from '@app/shared/enums/pages/pages.urls';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { decodeResponse } from '@app/shared/utils/utils';
import { TranslateService } from '@ngx-translate/core';

import { PtrabSessionManager } from './ptrab-session.manager.service';
import { SessionErrorHandler } from './session-error-handler/session-error.handler';
import { ConfirmExitService } from '../confirm-exit/confirm-exit.service';
import { ConfirmationController } from '../confirmation/confirmation.controller';
import { IrpfService } from '../irpf/irpf.service';

@Injectable({
  providedIn: 'root'
})
export class PtrabSessionInterceptor implements HttpInterceptor {
  private logger = new Logger('PtrabSessionInterceptor');
  compWindow: MSafeAny = window;

  constructor(
    private networkService: NetworkService,
    private navigationService: NavigationService,
    private userService: UserService,
    private location: Location,
    private ptrabSessionManager: PtrabSessionManager,
    private irpfService: IrpfService,
    private sessionErrorHandler: SessionErrorHandler,
    private confirmationCtrl: ConfirmationController,
    private translate: TranslateService,
    private confirmExitService: ConfirmExitService,
    private navHelperService: NavigationHelperService,
    private modalManager: ModalManager
  ) {}

  // eslint-disable-next-line
  intercept(request: HttpRequest<MSafeAny>, next: HttpHandler): Observable<MSafeAny> {
    return next.handle(request).pipe(
      catchError((err) => this.onError(err)),
      finalize(() => {
        this.sessionErrorHandler.enableToast();
      })
    );
  }

  // TODO: david.peris -> remove hasChanges() from irpfService to use only the hasChanges() of confirmExitService
  private onError(responseRaw: MSafeAny) {
    const error: HttpErrorResponse = decodeResponse(responseRaw);
    this.networkService.doIfConnection(async () => {
      this.logger.error(error);

      const isUnauthorized = this.isUnauthorized(error);
      const isBlocked = this.isBlocked(error);
      const isSectionMaintenance = this.isSectionMaintenance(error);
      this.ptrabSessionManager.setIsUnauthorized(isUnauthorized);
      this.ptrabSessionManager.setIsBlocked(isBlocked);
      this.ptrabSessionManager.setIsSectionMaintenance(isSectionMaintenance);

      if (isUnauthorized || isBlocked) {
        this.irpfService.removeWindowListener();
        this.irpfService.clearData();
        this.confirmExitService.cleanFormToCheck();

        if (this.isPtrabSection()) {
          this.modalManager.dismissMatModal();
          this.confirmSessionExpired(isBlocked, isUnauthorized);
          return;
        }
        this.goToRedirectPage(isBlocked, isUnauthorized);
      }
    });

    this.sessionErrorHandler.handleExternalError(error);
    return throwError(responseRaw);
  }

  private goToRedirectPage(isBlocked: boolean, isUnauthorized: boolean) {
    if (isBlocked || this.isRedirectUnauthorized(isUnauthorized)) {
      const navEvent = this.getRedirectionEvent();

      if (navEvent) {
        this.navigationService.navigate(navEvent);
      }
    }
  }

  /* Método para cambios o acceder a otra sección del ptrab */
  private isPtrabSection() {
    const currentWindowUrl = this.compWindow.location.pathname;
    const nextUrl = this.navHelperService.getNextUrl();
    return (
      (currentWindowUrl.includes('/ptrab') &&
        !currentWindowUrl.includes('/ptrab/login') &&
        nextUrl.includes('/ptrab') &&
        !nextUrl.includes('/ptrab/login')) ||
      (currentWindowUrl.includes('/personal-data') && nextUrl.includes('/personal-data'))
    );
  }

  /* Método para acceder desde el ptrab a una sección que no sea del ptrab */
  private isRedirectUnauthorized(isUnauthorized: boolean) {
    const nextUrl = this.navHelperService.getNextUrl();
    const currentWindowUrl = this.compWindow.location.pathname;
    return (
      isUnauthorized &&
      ((!currentWindowUrl.includes('/personal-data') && !currentWindowUrl.includes('/ptrab')) ||
        nextUrl.includes('/personal-data') ||
        nextUrl.includes('/ptrab'))
    );
  }

  private async confirmSessionExpired(isBlocked: boolean, isUnauthorized: boolean) {
    const confirmed = await this.confirmationCtrl.confirmAction({
      title: this.translate.instant('EMPLOYEE_PORTAL.COMMON_ERRORS.SESSION_NOT_AVAILABLE'),
      message: this.translate.instant('EMPLOYEE_PORTAL.COMMON_ERRORS.SESSION_NOT_AVAILABLE_DESCRIPTION'),
      confirmButtonText: this.translate.instant('ACCEPT'),
      cancelButtonText: null
    });
    if (confirmed) {
      this.goToRedirectPage(isBlocked, isUnauthorized);
      return;
    }
  }

  private isUnauthorized(response: HttpErrorResponse) {
    const isCodeUnauthorized = response.status === ErrorCodes.UNAUTHORIZED;
    return isCodeUnauthorized && response.error.code === ErrorMessages.PTRAB_UNAUTHORIZED;
  }

  private isBlocked(response: HttpErrorResponse) {
    const isForbidden = response.status === ErrorCodes.FORBIDDEN;
    return isForbidden && response.error.code === ErrorMessages.PTRAB_BLOCKED;
  }

  private isSectionMaintenance(response: HttpErrorResponse) {
    const isMaintenance = response.status === ErrorCodes.SECTION_MAINTENANCE;
    return isMaintenance && response.error.code === ErrorMessages.PERM_DEACTIVATED;
  }

  private getRedirectionEvent(): NavigationEvent | undefined {
    const currentLocation = this.location.path();
    const ptrabLoginRoute = getPtrabUrlFromMap(PTRAB_PAGES.PTRAB_LOGIN);

    if (currentLocation === ptrabLoginRoute) {
      return;
    }

    const navEvent = this.userService.hasAccessToPtrabSections()
      ? new NavigationEvent(NavigationEvents.Push, { path: ptrabLoginRoute })
      : new NavigationEvent(NavigationEvents.SetRoot, { path: PAGES.HOME });

    return navEvent;
  }
}
