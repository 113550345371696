import { finalize } from 'rxjs/operators';

import { ExamplesTabs } from '@app/activo2/examples/examples.enum';
import { PtrabSessionManager } from '@app/ptrab/services/session/ptrab-session.manager.service';
import { AppManagerService, ModalManager } from '@app/services';
import { LanguageService } from '@app/services/language/language.service';
import { LoadingService } from '@app/services/loading/loading.service';
import { Logger } from '@app/services/logger/logger.service';
import { NavigationEvent, NavigationEvents, NavigationService } from '@app/services/navigation/navigation.service';
import { NotificationService } from '@app/services/notification/notification.service';
import { AlertService } from '@app/services/alert/alert.service';
import { NotificationActions, NotificationPages } from '@app/shared/enums/notification/notification.enum';
import { MOT_PAGES, PAGES, PTRAB_PAGES } from '@app/shared/enums/pages/pages.enum';
import { getMotUrlFromMap, getPtrabUrlFromMap, getUrlFromMap } from '@app/shared/enums/pages/pages.urls';
import { Notification } from '@app/shared/models/notification/notification.model';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { domChanges } from '@app/shared/utils/utils';
import { TranslateService } from '@ngx-translate/core';
import { PayslipsTabs } from '@app/ptrab/payslips/payslips.enum';
import { CertificatesTabs } from '@app/ptrab/certificates/certificates.const';
/* eslint-disable @typescript-eslint/naming-convention */

export class NotificationHandler {
  private static readonly logger = new Logger('NotificationHandler');
  private static navService: NavigationService;
  private static notificationService: NotificationService;
  private static alertService: AlertService;
  private static translateService: TranslateService;
  private static modalManager: ModalManager;
  private static ptrabSessionManager: PtrabSessionManager;
  private static appManagerService: AppManagerService;
  private static loadingService: LoadingService;
  private static language: LanguageService;
  private static messages: MSafeAny;

  static domChanges = domChanges;

  static init(
    navService: NavigationService,
    notificationService: NotificationService,
    alertService: AlertService,
    translateService: TranslateService,
    modalManager: ModalManager,
    ptrabSessionManager: PtrabSessionManager,
    appManagerService: AppManagerService,
    loadingService: LoadingService,
    language: LanguageService
  ) {
    NotificationHandler.navService = navService;
    NotificationHandler.notificationService = notificationService;
    NotificationHandler.alertService = alertService;
    NotificationHandler.translateService = translateService;
    NotificationHandler.modalManager = modalManager;
    NotificationHandler.ptrabSessionManager = ptrabSessionManager;
    NotificationHandler.appManagerService = appManagerService;
    NotificationHandler.loadingService = loadingService;
    NotificationHandler.language = language;
    this.getTranslations();
  }

  static async handle(notification: Notification) {
    const languageCode = await this.language.getCurrentLanguage().toPromise();
    this.notificationService
      .assignAppNotifications(languageCode as string)
      .pipe(
        finalize(async () => {
          if (notification.id) {
            await this.notificationService.checkAsReadNotification(notification);
          }
          NotificationHandler.processAction(notification);
        })
      )
      .subscribe();
  }

  private static processAction(notification: Notification) {
    this.logger.debug('Process action:', notification);

    switch (notification.action) {
      case NotificationActions.SCROLL_TO_CONTENT:
      case NotificationActions.GO_TO_CONTENT:
        this.onNavigation(notification);
        break;
      case NotificationActions.GO_TO_LOCAL_PUBLICATION:
        this.onLocalPublicationNotice(notification);
        break;
      case NotificationActions.PUBLICATION_ACCEPTED:
        this.onPublishedExample(notification);
        break;
      case NotificationActions.PUBLICATION_REJECTED:
        this.onRejectedExample(notification);
        break;
      case NotificationActions.PUBLICATION_RETURNED:
        this.onReturnedExample(notification);
        break;
      default:
        this.logger.warn(`Notification with id "${notification.id}" has an unknown action: ${notification.action}`);
        break;
    }
  }

  private static onNavigation(notification: Notification) {
    const { action, section, page, item_id } = notification;
    const params = { action, section, item_id };

    if (!page) {
      return;
    }

    if (page === PAGES.PUBLICATIONS || page === PAGES.PUBLICATIONS_ORIGINAL) {
      return this.push(PAGES.ITEM, this.getCommentIdFromSection(Number(item_id), section as string));
    }

    if (page === PAGES.EXAMPLES) {
      return this.push(PAGES.ITEM_EXAMPLE, this.getCommentIdFromSection(Number(item_id), section as string));
    }

    if (page === NotificationPages.GARNISHMENT) {
      return this.push(PTRAB_PAGES.PAYSLIPS, {
        tab: PayslipsTabs.WAGE_GARNISHMENT
      });
    }

    if (page === NotificationPages.DOCUMENTS) {
      return this.push(PTRAB_PAGES.CERTIFICATES, {
        tab: CertificatesTabs.EMPLOYMENT
      });
    }

    this.push(page, params);
    return;
  }

  private static getCommentIdFromSection(item_id: number, comment_id: string): MSafeAny {
    const params: { item_id: number; comment_id?: number | null } = { item_id };
    if (comment_id) {
      params.comment_id = comment_id.includes('-') ? Number(comment_id.split('-')[1]) : null;
    }

    return params;
  }

  private static onLocalPublicationNotice(notification: Notification) {
    this.push(PAGES.ITEM_MY_PUBLICATION, {
      item_id: notification.item_id
    });
  }

  private static onPublishedExample(notification: Notification) {
    this.push(PAGES.EXAMPLES, {
      tab: ExamplesTabs.MY_EXAMPLES,
      item_id: notification.item_id
    });
  }

  private static onRejectedExample(notification: Notification) {
    this.push(PAGES.EXAMPLES, {
      tab: ExamplesTabs.MY_EXAMPLES,
      item_id: notification.item_id
    });
  }

  private static onReturnedExample(notification: Notification) {
    this.push(PAGES.EXAMPLES, {
      tab: ExamplesTabs.MY_EXAMPLES,
      item_id: notification.item_id
    });
  }

  private static async push(page: string, params?: MSafeAny) {
    try {
      await this.appManagerService.ready();
      const url = await NotificationHandler.getUrl(page, params);
      const navEvent = new NavigationEvent(NavigationEvents.Push, { path: url, navParams: params });
      this.modalManager.dismissAllMatModal();
      this.navService.navigate(navEvent);
    } catch (err) {
      this.getTranslations(() =>
        this.alertService.showError(
          this.messages['ERROR_MESSAGES.ERROR'],
          this.messages['NOTIFICATIONS_PAGE.NOTIFICATION_UNKNOWN_PAGE']
        )
      );
    }
  }

  private static async getUrl(page: string, params: MSafeAny) {
    let url;

    switch (true) {
      case Object.values(PTRAB_PAGES).includes(page as PTRAB_PAGES):
        url = getPtrabUrlFromMap(page as PTRAB_PAGES);
        await this.loadingService.show();
        await this.domChanges(2000);
        this.ptrabSessionManager.setPageToRedirectAfterLogin(url);
        this.ptrabSessionManager.setRedirectParamsAfterLogin(params);
        await this.loadingService.hide();
        break;
      case Object.values(MOT_PAGES).includes(page as MOT_PAGES):
        url = getMotUrlFromMap(page as MOT_PAGES);
        await this.loadingService.show();
        await this.domChanges(2000);
        break;
      default:
        url = getUrlFromMap(page, params.section);
        break;
    }

    return url;
  }

  private static getTranslations(callback?: () => void) {
    this.messages = [];
    this.translateService
      .get(['ERROR_MESSAGES.ERROR', 'NOTIFICATIONS_PAGE.NOTIFICATION_UNKNOWN_PAGE'])
      .subscribe((messages) => {
        this.messages = messages;
        if (callback) {
          callback();
        }
      });
  }
}
