import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { ApiPtrabUrls } from '@app/ptrab/services/api/api.service';
import { TwoFactorAuthorization } from '@app/ptrab/shared/interfaces/two-factor-authorization.interface';
import { ApiUrls, Logger, AlertService } from '@app/services';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { ErrorCodes, ErrorMessages } from '@app/services/error/error.model';
import { LoadingService } from '@app/services/loading/loading.service';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { decodeResponse } from '@app/shared/utils/utils';
import { ApiMotUrls } from '@mot/services';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class PdfService implements OnDestroy {
  messages: MSafeAny;
  private logger = new Logger('PdfService');
  private translationsSubscription: Subscription;

  constructor(
    private apiPtrabUrls: ApiPtrabUrls,
    private httpclient: HttpClient,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private alertService: AlertService,
    private analyticsService: AnalyticsService,
    private apiMotUrls: ApiMotUrls,
    private apiLogisticUrls: ApiUrls
  ) {
    this.initTranslations();
    this.translationsSubscription = this.translateService.onLangChange.subscribe(() => this.initTranslations());
  }

  // TODO: Refactor error handler, remove try catch blocks when calling this function
  async fetch(url: string, errorMsg?: string, isExternal = false, params = {}, isPtUser = false): Promise<void> {
    try {
      this.loadingService.show('DOWNLOADING');

      // eslint-disable-next-line
      if (isExternal) {
        return await this.openExternal(url);
      }

      const response = await this.httpclient.get(url, { responseType: 'arraybuffer', params }).toPromise();
      await this.open(response);
    } catch (err) {
      await this.loadingService.hide();

      this.handleError(errorMsg, err, isPtUser);
    }
  }

  handleError(errorMsg: string | undefined, err: MSafeAny, isPtUser?: boolean) {
    const decodedResponse = decodeResponse(err);
    this.logger.error(decodedResponse);
    const codeExceptions = [ErrorCodes.UNAUTHORIZED, ErrorCodes.BAD_REQUEST];

    // TODO: Temporary fix. Generic error management refactor is pending.
    if (decodedResponse?.error?.code === ErrorMessages.FIXED_DISCONTINUOUS_CERT_DOWNLOAD) {
      this.alertService.showError(
        this.messages['EMPLOYEE_PORTAL.VISUALIZATION_NOT_AVAILABLE'],
        decodedResponse.error.message
      );

      return;
    }

    if (codeExceptions.indexOf(decodedResponse.status) >= 0) {
      throw decodedResponse;
    }

    if (
      decodedResponse.status === ErrorCodes.SECTION_MAINTENANCE &&
      decodedResponse.error.code === ErrorMessages.PERM_DEACTIVATED
    ) {
      this.alertService.showError(
        this.messages['MAINTENANCE.SECTIONS_MAINTENANCE_TITLE'],
        `${this.messages['MAINTENANCE.SECTIONS_MAINTENANCE_1']} ${this.messages['MAINTENANCE.SECTIONS_MAINTENANCE_2']}`
      );
    } else {
      this.alertService.showError(
        this.messages['EMPLOYEE_PORTAL.VISUALIZATION_NOT_AVAILABLE'],
        isPtUser ? decodedResponse.error.message : errorMsg ? this.messages[errorMsg] : ''
      );
    }
  }

  async fetchPayslipPdf(payslipId: string) {
    try {
      const url = this.apiPtrabUrls.payslip.getFile(payslipId);
      await this.fetch(url, 'EMPLOYEE_PORTAL.ERROR_DOWNLOAD');
    } catch (err) {
      this.logger.error(err);
      if (decodeResponse(err).status === ErrorCodes.UNAUTHORIZED) {
        return;
      }

      this.alertService.showError(
        this.messages['ERRORS_TOASTS.GENERIC_MSG'],
        this.messages['ERROR_MESSAGES.GENERIC_FAIL_MESSAGE']
      );
    }
  }

  async fetchMOTVacationsPdf() {
    try {
      let params = new HttpParams();
      params = params.set('lang', this.translateService.currentLang);
      const url = this.apiMotUrls.vacations.pdf;
      await this.fetch(url, 'MOT.VACATIONS.ERRORS.DOWNLOAD_PDF', false, params);
    } catch (err) {
      this.logger.error(err);
    }
  }

  async fetchLogisticCalendarPdf(year: string) {
    try {
      let params = new HttpParams();
      params = params.set('lang', this.translateService.currentLang);
      params = params.set('year', year);
      const url = this.apiLogisticUrls.logistic_calendar.pdf;
      await this.fetch(url, 'MOT.VACATIONS.ERRORS.DOWNLOAD_PDF', false, params);
    } catch (err) {
      this.logger.error(err);
    }
  }

  async fetchCarTransferPdf(carTransferId: string) {
    try {
      const url = this.apiPtrabUrls.carTransfer.getFile(carTransferId);
      await this.fetch(url, 'EMPLOYEE_PORTAL.CAR_TRANSFER_ERROR_DOWNLOAD');
    } catch (err) {
      this.logger.error(err);
    }
  }

  async fetchSignedTimetablePdf(documentId: string) {
    try {
      const url = `${this.apiMotUrls.signed_timetables.list}/${documentId}`;
      await this.fetch(url, 'EMPLOYEE_PORTAL.CARD_VIEW_ERROR_DOWNLOAD');
    } catch (err) {
      this.logger.error(err);
    }
  }

  async fetchSignedVacationsPdf(documentId: string) {
    try {
      const url = `${this.apiMotUrls.vacations.signed}/${documentId}`;
      await this.fetch(url, 'EMPLOYEE_PORTAL.SIGNED_CALENDAR_ERROR_DOWNLOAD');
    } catch (err) {
      this.logger.error(err);
    }
  }

  /* istanbul ignore next */

  // eslint-disable-next-line
  fetchDirectDebitPdf(url: string, authorization: TwoFactorAuthorization, errorMsg?: string) {
    this.loadingService.show();

    this.httpclient
      .post(
        url,
        {
          authorization
        },
        {
          observe: 'response',
          responseType: 'arraybuffer'
        }
      )
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(
        (response) => {
          this.open(response.body);
        },
        (err: HttpErrorResponse) => {
          this.handleError(errorMsg, err);
        }
      );
  }

  // eslint-disable-next-line
  ngOnDestroy() {
    this.translationsSubscription.unsubscribe();
  }

  private initTranslations() {
    this.translateService
      .get([
        'EMPLOYEE_PORTAL.WITHHOLDING_CERTIFICATE_ERROR_TITLE',
        'EMPLOYEE_PORTAL.CAR_TRANSFER_ERROR_DOWNLOAD',
        'EMPLOYEE_PORTAL.CARD_VIEW_ERROR_DOWNLOAD',
        'EMPLOYEE_PORTAL.SIGNED_CALENDAR_ERROR_DOWNLOAD',
        'EMPLOYEE_PORTAL.VISUALIZATION_NOT_AVAILABLE',
        'EMPLOYEE_PORTAL.NO_CHANGE_IN_BANK_ACCOUNT',
        'EMPLOYEE_PORTAL.WITHHOLDING_CERTIFICATE_ERROR_DESC',
        'EMPLOYEE_PORTAL.PTRAB-MC-RQ-OM-FIS-1021',
        'EMPLOYEE_PORTAL.PTRAB-MC-RQ-UNSUPPORTED',
        'EMPLOYEE_PORTAL.PTRAB-MC-RQ-OM-ERR-1000',
        'EMPLOYEE_PORTAL.PTRAB-MC-RQ-OM-FIS-1001',
        'EMPLOYEE_PORTAL.WITHHOLDING_CERTIFICATE_ERROR_NO_PDF_VIEWER',
        'MAINTENANCE.SECTIONS_MAINTENANCE_TITLE',
        'MAINTENANCE.SECTIONS_MAINTENANCE_1',
        'MAINTENANCE.SECTIONS_MAINTENANCE_2',
        'ERRORS_TOASTS.GENERIC_MSG',
        'ERROR_MESSAGES.GENERIC_FAIL_MESSAGE'
      ])
      .subscribe((messages) => (this.messages = messages));
  }

  private async openExternal(url: string) {
    await this.loadingService.hide();
    window.open(url, '_blank');
  }

  private async open(response: MSafeAny) {
    try {
      const blob: Blob = new Blob([response], { type: 'application/pdf' });
      this.logger.debug('blob is:', blob);

      const fileurl: string = window.URL.createObjectURL(blob);
      this.logger.debug('fileurl is:', fileurl);

      await this.openExternal(fileurl);
    } catch (err) {
      await this.loadingService.hide();
      this.logger.error(err);
      throw err;
    }
  }

  private getFilename() {
    return `${Date.now()}.pdf`;
  }
}
