import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { InputComponent } from '@app/components/input/input.component';

import { DropdownItem } from '@app/ptrab/shared/models/dropdown';
import { Logger } from '@app/services';
import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { normalizeString, isValidString, removeHtmlTagsFromString } from '@app/shared/utils/utils';
import { debounceTime, switchMap, of } from 'rxjs';

const TWO_MILLISECONDS = 200;

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss']
})
export class SelectSearchComponent implements OnChanges, AfterViewInit {
  private readonly logger: Logger = new Logger('SelectSearchComponent');

  @ViewChild(InputComponent) searchInput!: InputComponent;
  searchInputEvent!: EventEmitter<MSafeAny>;
  @Input() items!: DropdownItem[];
  @Input() placeholder!: string;
  @Input() label!: string;
  @Input() disabled!: boolean;

  initialItems: DropdownItem[] = [];
  itemsFiltered: DropdownItem[] = [];
  previousSelection: DropdownItem | undefined;

  @Output() selectOption = new EventEmitter<MSafeAny>();

  toggle = false;
  hasBeenOpened = false;

  searchTerm!: string;

  @HostListener('document:click')
  clickout() {
    if (!this.hasBeenOpened) {
      this.toggle = false;

      if (this.previousSelection) {
        this.previousSelection.selected = true;
        this.searchTerm = removeHtmlTagsFromString(this.previousSelection.label);
      }
    }

    this.hasBeenOpened = false;
  }

  ngAfterViewInit(): void {
    this.searchInputEvent = this.searchInput.modelChange;
    this.loadPredictiveResults();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      this.initializeArrays();
      const selectedItem = this.items.find((item) => item.selected);

      if (selectedItem) {
        this.previousSelection = selectedItem;
        this.searchTerm = this.previousSelection.label;
      } else {
        this.searchTerm = '';
      }
    }
  }

  /* istanbul ignore next */
  // eslint-disable-next-line
  loadPredictiveResults() {
    let value: string;
    this.searchInputEvent
      .pipe(
        debounceTime(TWO_MILLISECONDS),
        switchMap((inputValue: string) => {
          this.items = JSON.parse(JSON.stringify(this.initialItems));
          value = inputValue;
          this.search(value);
          if (value) {
            return of(this.itemsFiltered);
          }
          return of(null);
        })
      )

      .subscribe({
        next: (searchPredictive) => {
          if (searchPredictive) {
            searchPredictive.forEach((record) => {
              if (record.selectable) {
                const found = normalizeString(record.label)
                  .toLowerCase()
                  .indexOf(normalizeString(value).toLowerCase() || normalizeString(record.label).toLowerCase());

                if (found >= 0) {
                  record.label = [
                    record.label.slice(0, found),
                    '<b>',
                    record.label.slice(found, found + value.length),
                    '</b>',
                    record.label.slice(found + value.length)
                  ].join('');
                }
              }
            });
          }
        },
        error: (error) => this.logger.error(error)
      });
  }

  search(event?: MSafeAny) {
    /* istanbul ignore next */
    // eslint-disable-next-line
    const termToSearch = event ? event.replace(/[&\/\\#,()$~%.'":*?<>{}]/g, '').trim() : this.searchTerm;
    if (isValidString(termToSearch)) {
      this.itemsFiltered = this.items.filter((item) =>
        normalizeString(removeHtmlTagsFromString(item.label))
          .toLowerCase()
          .includes(normalizeString(termToSearch).toLowerCase())
      );
    } else {
      this.itemsFiltered = this.items;
    }

    this.toggle = true;
  }

  trackByItems(item: DropdownItem) {
    return item;
  }

  onModelChange(term: string) {
    this.searchTerm = term;
  }

  selectItem(selectedItem: DropdownItem) {
    this.unselectItems(this.items);
    selectedItem.selected = true;
    this.previousSelection = selectedItem;
    this.toggle = false;
    const htmlString = this.items.find((item) => item.selected === true)!.label;
    this.searchTerm = removeHtmlTagsFromString(htmlString);
    this.updateInitialArrays();
    this.selectOption.emit(selectedItem);
  }

  unselectItems(items: DropdownItem[]) {
    items.forEach((item) => (item.selected = false));
  }

  showDropdown() {
    if (this.disabled) {
      return;
    }

    this.hasBeenOpened = true;
    this.toggle = true;
    this.search();
  }

  onCloseIconClick(event: Event) {
    event.stopPropagation();
    this.toggle = false;
    this.searchTerm = '';
    this.unselectItems(this.items);
    this.selectOption.emit();
  }

  private updateInitialArrays() {
    this.initializeArrays();
    this.initialItems.forEach((item) => (item.label = removeHtmlTagsFromString(item.label)));
  }

  private initializeArrays() {
    this.initialItems = JSON.parse(JSON.stringify(this.items));
  }
}
