/* eslint-disable no-undef */
import { ComponentType } from '@angular/cdk/overlay';
import { Injectable, Injector } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';

import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ModalManager {
  private currentMatModal!: MatDialogRef<MSafeAny> | null;
  private authService: AuthService;
  private readonly cssClass = 'hidden';

  constructor(private injector: Injector, private dialog: MatDialog) {
    this.authService = this.injector.get(AuthService);
  }

  async dismissAllModalIfNoSession() {
    const hasSession = await this.authService.hasActivo2Session();
    if (!hasSession) {
      this.dismissAllMatModal();
    }
  }

  isAnyModalOpened() {
    return this.dialog.openDialogs?.length;
  }

  updateCurrentModalView(hidden = false) {
    if (this.currentMatModal) {
      if (hidden) {
        this.currentMatModal.addPanelClass(this.cssClass);
      } else {
        this.currentMatModal.removePanelClass(this.cssClass);
      }
    }
  }

  dismissMatModal(success: MSafeAny = false) {
    if (this.currentMatModal) {
      this.dialog.openDialogs.pop();
      this.currentMatModal?.close(success);
      this.setCurrentMatModal();
    }
  }

  dismissAllMatModal() {
    this.dialog.closeAll();
  }

  async createMatModal(
    component: ComponentType<MSafeAny>,
    modalOpts: MatDialogConfig
  ): Promise<MatDialogRef<MSafeAny> | null> {
    const modal = await this.addMatModal(component, modalOpts);
    return this.onDidDismissCurrentMatModal(modal);
  }

  openMatModal(component: ComponentType<MSafeAny>, modalOpts: MatDialogConfig): Promise<MSafeAny> {
    const modal = this.addMatModal(component, modalOpts);
    return this.dismissCurrentMatModal(modal);
  }

  private setCurrentMatModal() {
    if (this.dialog.openDialogs.length) {
      this.currentMatModal = this.dialog.openDialogs[this.dialog.openDialogs.length - 1];
      return;
    }

    this.currentMatModal = null;
  }

  private addMatModal(component: ComponentType<MSafeAny>, modalOpts: MatDialogConfig) {
    const modal = this.dialog.open(component, modalOpts);
    this.currentMatModal = modal;
    return modal;
  }

  // In case modal dismiss by clicking android back button or modal backdrop
  private removeCurrentMatModal(modal: MatDialogRef<MSafeAny>) {
    if (this.currentMatModal === modal) {
      this.dialog.openDialogs.pop();
      this.setCurrentMatModal();
    }
  }

  private async dismissCurrentMatModal(modal: MatDialogRef<MSafeAny>): Promise<MSafeAny> {
    return new Promise((resolve) => {
      this.currentMatModal?.afterClosed().subscribe((value) => {
        resolve(value);
        this.removeCurrentMatModal(modal);
      });
    });
  }

  private async onDidDismissCurrentMatModal(modal: MatDialogRef<MSafeAny>) {
    this.currentMatModal?.afterClosed().subscribe(() => this.removeCurrentMatModal(modal));
    return this.currentMatModal;
  }
}
