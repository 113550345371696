import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { MSafeAny } from '@app/shared/models/safe-any/safe-any.model';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { MSpinnerRef, MSpinnerGlobalService, MSpinnerGlobalOptions } from '@mercadona/components/spinner';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private spinner!: MSpinnerRef;
  private isHidden = true;
  private isStarting$ = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line
  private loader!: HTMLIonLoadingElement;

  constructor(
    private translate: TranslateService,
    private loadingCtrl: LoadingController,
    private mSpinnerGlobalService: MSpinnerGlobalService
  ) {}

  async show(msg?: string) {
    if (this.isHidden) {
      this.isStarting$.next(true);

      const options: MSpinnerGlobalOptions = {
        message: this.translate.instant(msg || 'LOADING_MESSAGE'),
        size: 'small'
      };
      this.spinner = this.mSpinnerGlobalService.create(options);

      this.isHidden = false;
      this.isStarting$.next(false);
    }
    // if (this.isHidden || !this.loader) {
    //   this.isStarting$.next(true);

    //   this.loader = await this.loadingCtrl.create({
    //     message: this.translate.instant(msg || 'LOADING_MESSAGE'),
    //     cssClass: 'c-loading'
    //   });

    //   await this.loader?.present();

    //   this.isHidden = false;
    //   this.isStarting$.next(false);
    // }
  }

  async hide(): Promise<MSafeAny> {
    await this.isStarting$.pipe(takeWhile((isStarting) => isStarting)).toPromise();

    if (!this.spinner) {
      this.isHidden = true;
      return;
    }

    this.isHidden = true;

    return this.spinner.close();

    //   await this.isStarting$.pipe(takeWhile((isStarting) => isStarting)).toPromise();

    //   if (!this.loader) {
    //     this.isHidden = true;
    //     return;
    //   }

    //   this.isHidden = true;

    //   const loader = await this.loadingCtrl.getTop();

    //   if (!loader) {
    //     return;
    //   }

    //   return this.loadingCtrl.dismiss();
  }
}
